import {Datagrid, Filter, FunctionField, TextField, TextInput, TopToolbar} from "react-admin";
import React from "react";
import CrudMod from "../../../Component/Layouts/Button/CrudMod";
import CreateGradientButtonMod from "../../../Component/Layouts/Button/CreateGradientButtonMod";
import ListMod from "../../../Component/Redesign/ListMod";

const Filters = (props) => (
    <Filter {...props}>
        <TextInput variant="standard" label="Search" source="search" alwaysOn/>
    </Filter>
);

const SubscriptionField = ({record}) => {
    if (!record.subscription) return 'Not Required';
    return (
        <span>{record.subscription === 1 && 'Required'}</span>
    );
}

const MainField = ({record}) => {
    if (!record.main) return 'No';
    return (
        <span>{record.main === 1 && 'Yes'}</span>
    );
}

const OnboardingField = ({record}) => {
    if (!record.onboarding) return 'No';
    return (
        <span>{record.onboarding === 1 && 'Yes'}</span>
    );
}

const LocalsField = ({record}) => {
    if (!record.locales || !record.locales.length) {
        return '';
    }

    const lastElement = record.locales.length - 1;
    return record.locales.map((item, i) => (
        <span key={i}>{item.locale}{i === lastElement ? '' : ', '}</span>
    ))
};

export const Categories = (props) => {
    const ListActions = ({className}) => {
        return (<TopToolbar className={className}>
            <div className="actionsWrap">
                <div className="addCategoryBtnWrap">
                    <CreateGradientButtonMod label={'Add New Category'}/>
                </div>
            </div>
        </TopToolbar>);
    };

    return (
        <div className="customTableWrap">
            <ListMod {...props} title="Categories" filters={<Filters/>} actions={<ListActions/>}
                     resource="categories/general" sort={{field: 'order', order: 'ASC'}}>
                <Datagrid bulkActionButtons={false} className="customTable categories-table">
                    <TextField label='ID' source="id" sortBy="id"/>
                    <TextField label='Name' source="name" sortBy="name"/>
                    <FunctionField label='Description' source="description" sortBy="description" render={(record) => {
                        return (
                            <span title={record.description}>{record.description}</span>
                        );
                    }}/>
                    <FunctionField label='Subscription' source="subscription" sortBy="subscription"
                                   render={(record) => {
                                       return <SubscriptionField record={record}/>
                                   }}/>

                    <TextField label='Order' source="order" sortBy="order"/>
                    <FunctionField label='Main' source="main" sortBy="main" render={(record) => {
                        return <MainField record={record}/>
                    }}/>
                    <FunctionField label='Onboarding' source="onboarding" sortBy="onboarding" render={(record) => {
                        return <OnboardingField record={record}/>
                    }}/>
                    <FunctionField label='Localization' source="locales" sortBy="locales" render={(record) => {
                        return <LocalsField record={record}/>
                    }}/>
                    <FunctionField label="" source="actions" render={(record) => {
                        return <CrudMod edit="true" del="true" id={record.id} record={record}/>;
                    }}/>
                </Datagrid>
            </ListMod>
        </div>
    )
}

export default Categories;
